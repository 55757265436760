import IMAGES from '../../constants/images/album'

const data = [
  {
    id: 1,
    image: IMAGES.IMG1,
    title: 'Vikoda - Dubai',
    year: '2024'
  },
  {
    id: 2,
    image: IMAGES.IMG2,
    title: 'MSB Prudential - Australia',
    year: '2024'
  },
  {
    id: 3,
    image: IMAGES.IMG3,
    title: "L'oreal - Thailand",
    year: '2023'

  },
  {
    id: 4,
    image: IMAGES.IMG4,
    title: 'PVD Drilling - China',
    year: '2024'
  },
  {
    id: 5,
    image: IMAGES.IMG5,
    title: 'Lễ tôn vinh Hướng Dẫn Viên - Vietravel',
    year: '2023'
  },
  {
    id: 6,
    image: IMAGES.IMG6,
    title: 'Aviva - Italy',
    year: '2019'
  },
  {
    id: 7,
    image: IMAGES.IMG7,
    title: 'Crowe - Malaysia',
    year: '2019'
  },
  {
    id: 8,
    image: IMAGES.IMG8,
    title: 'APA - Mũi Né',
    year: '2020'
  },
  {
    id: 9,
    image: IMAGES.IMG9,
    title: 'Chương trình khuyến mãi du lịch Thái Lan - Vietravel',
    year: '2023'
  },
  {
    id: 10,
    image: IMAGES.IMG10,
    title: 'Lộc Trời - Thái Lan',
    year: '2024'
  },
  {
    id: 11,
    image: IMAGES.IMG11,
    title: 'Lễ Tốt Nghiệp - Du Lịch Sài Gòn',
    year: '2020'
  },
  {
    id: 12,
    image: IMAGES.IMG12,
    title: 'VPBank - Dubai',
    year: '2024'
  },
  {
    id: 13,
    image: IMAGES.IMG13,
    title: 'MSB - Sydney, Australia',
    year: '2024'

  },
  {
    id: 14,
    image: IMAGES.IMG14,
    title: 'Viện Kiểm Sát Nhân Dân Cấp Cao - Vũng Tàu',
    year: '2020'
  },
  {
    id: 15,
    image: IMAGES.IMG15,
    title: 'Hào Nam - Vũng Tàu',
    year: '2020'
  },
  {
    id: 16,
    image: IMAGES.IMG16,
    title: 'ITE Trung Thu - Vietravel',
    year: '2023'
  },
  {
    id: 17,
    image: IMAGES.IMG17,
    title: 'Lễ Trao Bằng Tốt Nghiệp - Du Lịch Sài Gòn',
    year: '2022'
  },
  {
    id: 18,
    image: IMAGES.IMG18,
    title: 'Tập Đoàn Xây Dựng Hoà Bình - Bến Tre',
    year: '2021'
  },
  {
    id: 19,
    image: IMAGES.IMG19,
    title: "L'oreal - Thailand",
    year: '2023'
  },
  {
    id: 20,
    image: IMAGES.IMG20,
    title: 'VICEM Hà Tiên - China',
    year: '2024'
  },
  {
    id: 21,
    image: IMAGES.IMG21,
    title: 'MSB Prudential - Taiwan',
    year: '2024'
  },
  {
    id: 22,
    image: IMAGES.IMG22,
    title: 'Hollywood, United States',
    year: '2023'
  },
  {
    id: 23,
    image: IMAGES.IMG23,
    title: 'Seoul, South Korea',
    year: '2024'

  },
  {
    id: 24,
    image: IMAGES.IMG24,
    title: 'TPS - Phan Thiết',
    year: '2020'
  },
  {
    id: 25,
    image: IMAGES.IMG25,
    title: 'New York, United States',
    year: '2023'
  },
  {
    id: 26,
    image: IMAGES.IMG26,
    title: 'Grand Canyon, United States',
    year: '2024'
  },
  {
    id: 27,
    image: IMAGES.IMG27,
    title: 'BeiJing, China',
    year: '2024'
  },
  {
    id: 28,
    image: IMAGES.IMG28,
    title: 'Lễ Trao Bằng Tốt Nghiệp - Du Lịch Sài Gòn',
    year: '2022'
  },
  {
    id: 29,
    image: IMAGES.IMG29,
    title: 'Hahndorf - Adelaide, Australia',
    year: '2022'
  },
  {
    id: 30,
    image: IMAGES.IMG30,
    title: 'Viện Kiểm Sát Nhân Dân Cấp Cao - Vũng Tàu',
    year: '2020'
  },
  {
    id: 31,
    image: IMAGES.IMG31,
    title: 'Dubai, United Arab Emirates',
    year: '2023'
  },
  {
    id: 32,
    image: IMAGES.IMG32,
    title: 'Bali, Indonesia',
    year: '2022'
  },
  {
    id: 33,
    image: IMAGES.IMG33,
    title: 'ITE - Vietravel',
    year: '2024'

  },
  {
    id: 34,
    image: IMAGES.IMG34,
    title: 'Carrick Hill - Adelaide, Australia',
    year: '2023'
  },
  {
    id: 35,
    image: IMAGES.IMG35,
    title: 'Carrick Hill - Adelaide, Australia',
    year: '2023'
  },
  {
    id: 36,
    image: IMAGES.IMG36,
    title: 'Adelaide Oval - Adelaide, Australia',
    year: '2024'
  },
  {
    id: 37,
    image: IMAGES.IMG37,
    title: 'Sydney, Australia',
    year: '2024'
  },
  {
    id: 38,
    image: IMAGES.IMG38,
    title: 'MSB Prudential - Australia',
    year: '2024'
  },
  {
    id: 39,
    image: IMAGES.IMG39,
    title: 'Pascalia Asia - Bali, Indonesia',
    year: '2023'
  },

]

export default data