import IMG1 from '../../assets/1_Dubai.JPG'
import IMG2 from '../../assets/2_Australia.JPG'
import IMG3 from '../../assets/3_Thailand.JPG'
import IMG4 from '../../assets/4_China.JPG'
import IMG5 from '../../assets/5_Vietravel.JPG'
import IMG6 from '../../assets/6_Italy.JPG'
import IMG7 from '../../assets/7_Malaysia.JPG'
import IMG8 from '../../assets/8_MuiNe.JPG'
import IMG9 from '../../assets/9_Vietravel.JPG'
import IMG10 from '../../assets/10_Thailand.JPG'
import IMG11 from '../../assets/11_Vietravel.JPG'
import IMG12 from '../../assets/12_Dubai.JPG'
import IMG13 from '../../assets/12_Sydney.JPG'
import IMG14 from '../../assets/13_VungTau.JPG'
import IMG15 from '../../assets/14_VungTau.JPG'
import IMG16 from '../../assets/15_TrungThu.JPG'
import IMG17 from '../../assets/16_Vietravel.JPG'
import IMG18 from '../../assets/17_BenTre.JPG'
import IMG19 from '../../assets/18_Thailand.JPG'
import IMG20 from '../../assets/19_China.JPG'
import IMG21 from '../../assets/20_Taiwan.JPG'
import IMG22 from '../../assets/21.JPG'
import IMG23 from '../../assets/22.JPG'
import IMG24 from '../../assets/23.JPG'
import IMG25 from '../../assets/24.JPG'
import IMG26 from '../../assets/25.JPG'
import IMG27 from '../../assets/26.JPG'
import IMG28 from '../../assets/27.JPG'
import IMG29 from '../../assets/28.JPG'
import IMG30 from '../../assets/29.JPG'
import IMG31 from '../../assets/31.JPG'
import IMG32 from '../../assets/32.JPG'
import IMG33 from '../../assets/33.JPG'
import IMG34 from '../../assets/34.JPG'
import IMG35 from '../../assets/35.JPG'
import IMG36 from '../../assets/36.JPG'
import IMG37 from '../../assets/37.JPG'
import IMG38 from '../../assets/38.JPG'
import IMG39 from '../../assets/30.JPG'


export default {
    IMG1,
    IMG2,
    IMG3,
    IMG4,
    IMG5,
    IMG6,
    IMG7,
    IMG8,
    IMG9,
    IMG10,
    IMG11,
    IMG12,
    IMG13,
    IMG14,
    IMG15,
    IMG16,
    IMG17,
    IMG18,
    IMG19,
    IMG20,
    IMG21,
    IMG22,
    IMG23,
    IMG24,
    IMG25,
    IMG26,
    IMG27,
    IMG28,
    IMG29,
    IMG30,
    IMG31,
    IMG32,
    IMG33,
    IMG34,
    IMG35,
    IMG36,
    IMG37,
    IMG38,
    IMG39
  };